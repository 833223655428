<template>
  <div class="detail-container">
    <div class="operator-bar">
      <el-button type="primary" @click="goEdit">编辑</el-button>
    </div>
    <div class="detail-info" v-loading="loading">
      <el-row>
        <el-col :span="3">banner名称：</el-col>
        <el-col :span="21">{{info.bannerName}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3">广告位图：</el-col>
        <el-col :span="21"><img :src="info.thumb" class="thumb" width="150"></el-col>
      </el-row>
      <el-row>
        <el-col :span="3">幻灯片图：</el-col>
        <el-col :span="21"><img :src="info.image" class="image" width="750"></el-col>
      </el-row>
      <el-row>
        <el-col :span="3">状态：</el-col>
        <el-col :span="21">
          <el-radio v-model="info.bannerStatus" label="OPEN" border :disabled="info.bannerStatus == 'HIDDEN'">开启</el-radio>
          <el-radio v-model="info.bannerStatus" label="HIDDEN" border :disabled="info.bannerStatus == 'OPEN'">隐藏</el-radio>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">链接地址：</el-col>
        <el-col :span="21">{{info.bannerLink}}</el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {getBannerDetail} from '@/api/banner'

export default {
  data() {
    return {
      bannerId: this.$route.query.bannerId,
      info: {},
      loading: true
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      getBannerDetail({bannerId:this.bannerId}).then(res => {
        this.info = res.data
        this.loading = false
      })
    },
    goEdit() {
      const path = this.$route.matched.slice(this.$route.matched.length-2, this.$route.matched.length-1)[0].path
      this.$router.push(path+'/edit?bannerId='+this.bannerId)
    }
  }
}
</script>
